import {Link} from "react-router-dom";
import SectionTitle from "../SectionTitle";

import serviceImg from '../../../assets/images/service/service-img.jpg';
import chooseUsImg from '../../../assets/images/about/chooseus.png';

const AboutUsSection = () => {
    return (
        <>
        <section className="service-section pt-70 rpt-90 pb-70 rpb-100">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <SectionTitle title={'Qui sommes-nous ?'} extraClass={'text-center'}/>
                    </div>
                </div>
                <div className="row service-details-content">
                    <div className="col-lg-8 col-xl-9">
                        <div className="row align-items-center m-auto text-justify pt-55" style={{width:"80%"}}>
                            <blockquote>{"Notre société est une entreprise spécialisée dans la prestation de services dans le domaine d'ingénierie industrielle et R&D. "}</blockquote>
                            <p>{"Nous proposons une gamme complète de services pour accompagner nos clients à développer leurs activités, améliorer leurs productivités et accroître leurs compétitivités sur le marché."}</p>
                            <p>{"Nous intervenons tout au long du cycle de vie du projet, commençant par les phases d'études préliminaires, en réalisant les phases de conception et de développement jusqu'aux phases de la maintenance du produit."}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-xl-3">
                        <div className="service-img" style={{background: `url(${serviceImg})`}}>
                            <div className="service-details-btn">
                                <Link to={"/contact"} className="btn theme-btn">
                                    {"Contactez nous"}
                                    <i className="icofont-double-right"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="service-section rpt-90 pb-70 rpb-100">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <SectionTitle title={'Pourquoi nous choisir ?'} extraClass={'text-center'}/>
                    </div>
                </div>
                <div className="row service-details-content">
                    <div className="col-lg-5 col-xl-5">
                        <div className="service-img" style={{background: `white url(${chooseUsImg})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center", maxWidth: "445px", maxHeight:"250px"}}></div>
                    </div>
                    <div className="col-lg-6 col-xl-6 pt-65">
                        <div className="row align-items-center m-auto text-justify">
                            <blockquote>{"Nous disposons d'une équipe d'experts hautement qualifiés et expérimentés dans les domaines de l'ingénierie, de la recherche et du développement, qui travaillent en étroite collaboration avec nos Clients pour répondre à leurs besoins spécifiques."}</blockquote>
                            <p>{"Pour apporter un meilleur résultat à ses Clients, Protec Engineering s'appuie sur sa collaboration avec des grandes entreprises expertes dans plusieurs domaines d'activités dont l'excellence opérationnelle, l'ingénierie & Industrialisation et le management de transition."}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
    );
}

export default AboutUsSection;