import React from "react";
import PageBanner from "../Utilities/PageBanner";
import ServiceDetailsSection from "../Utilities/ServiceDetailsSection/ServiceDetailsSection";
import { useLocation } from "react-router-dom";

const Services = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const service = searchParams.get("service");

    return (
        <>
            <PageBanner
                pageTitle={'Notre expertise'}
                currentPage={'Expertises'}
            />
            <ServiceDetailsSection service={ service }/>
        </>
    );
}

export default Services;