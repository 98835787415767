
const Footer = () => {
    return (
        <footer className="main-footer">
            {/* <!-- Copyright Area --> */}
            <div className="copy-right-area" >
                <div className="container">
                    <div className="copy-right-text text-center">
                        <p><span>{"Copyright"}</span> {"© 2022 | Protec-Engineering"}</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer