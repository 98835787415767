import detailsImg from '../../assets/images/service/defense.png';

const Defense = () => {

    return (
        <div className="col-lg-8">
            <div className="service-details-content">
                <div className="service-details-image">
                    <img src={detailsImg} alt="Service Details Image"/>
                </div>
                <h2>{"Défense"}</h2>
                <p>{"Protec Engineering intervient chez ses Clients dans le domaine de la Défense pour les aider à identifier leurs besoins, à concevoir des solutions personnalisées et à mettre en œuvre des projets de grande envergure.\n" +
                    "Nos services proposés peuvent inclure la conception, la modélisation, la simulation, la gestion de projet, l'analyse de rentabilisation et l'expertise technique. Nos experts peuvent également fournir des conseils en matière de conformité réglementaire et de gestion de la chaine d'approvisionnement. "}</p>
            </div>
        </div>
    );
}

export default Defense;