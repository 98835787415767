import React, {useEffect} from "react";
import {
    BrowserRouter as Router, Switch, Route
} from "react-router-dom";

import Footer from "./Utilities/Footer";
import Header from "./Utilities/Header";
import Home from "./Pages/Home";
import Services from "./Pages/Services";
import Careers from "./Pages/Careers";
import Contact from "./Pages/Contact";

const App = () => {
    return (
        <Router>
            <div className="page-wrapper">
                <Header/>
                <Switch>
                    <Route  exact path={'/'} component={Home}/>
                    <Route exact path={'/services'} component={Services}/>
                    <Route exact path={'/careers'} component={Careers}/>
                    <Route exact path={'/contact'} component={Contact}/>
                </Switch>
                <Footer/>
            </div>
        </Router>
    );
}

export default App;
