import contactImg from '../../../assets/images/get-touch-img.png';
const ContactInfo = () => {
    return (
        <div className="get-touch-left">
            <div className="info-box">
                <div className="info-icon">
                    <i className="icofont-google-map"></i>
                </div>
                <div className="info-details">
                    <p>Espace Carco, 24 Rue Robert Desnos </p><p> 69120, Vaulx-en-Velin</p>
                </div>
            </div>
            <div className="info-box">
                <div className="info-icon">
                    <i className="icofont-phone"></i>
                </div>
                <div className="info-details">
                    <a href="#">{"+33 (0)7 83 89 93 11"}</a>
                </div>
            </div>
            <div className="info-box">
                <div className="info-icon">
                    <i className="icofont-email"></i>
                </div>
                <div className="info-details">
                    <a href="mailto:contact@protec-engineering.fr">{"contact@protec-engineering.fr"}</a>
                </div>
            </div>
            <div className="get-touch-img">
                <img src={contactImg} alt={"Get Touch Image"} />
            </div>
        </div>
    );
}

export default ContactInfo;