import aboutImg1 from '../../../assets/images/about/about-img1.jpg';
import aboutImg2 from '../../../assets/images/about/about-img2.jpg';
import SectionTitle from '../SectionTitle';
import {Link} from "react-router-dom";
import React from "react";

const CareerSection = () => {
    return (
        <>
            <section className="about-us pt-120 rpt-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="about-left">
                                <div className="experience-years">
                                </div>
                                <div className="about-image">
                                    <img src={aboutImg1} alt="Careers Image"/>
                                </div>
                                <div className="about-image-two">
                                    <img src={aboutImg2} alt="Careers Image2"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="about-choose-us-left">
                                        <div className="section-title">
                                            <h5>{"Pour répondre aux demandes de nos clients, nous recrutons régulièrement des consultants ayant des expertises dans les domaines suivants :"}</h5>
                                        </div>
                                        <div className="chooseus-list">
                                            <ul>
                                                <li>
                                                    <i className="icofont-check"></i>
                                                    {"Gestion de projet"}
                                                </li>
                                                <li>
                                                    <i className="icofont-check"></i>
                                                    {"Amélioration de la productivité"}
                                                </li>
                                                <li>
                                                    <i className="icofont-check"></i>
                                                    {"Maintenance et réparation"}
                                                </li>
                                                <li>
                                                    <i className="icofont-check"></i>
                                                    {"Gestion de la chaine d'approvisionnement"}
                                                </li>
                                                <li>
                                                    <i className="icofont-check"></i>
                                                    {"Formation et coaching"}
                                                </li>
                                                <li>
                                                    <i className="icofont-check"></i>
                                                    {"Conception et développement de produits"}
                                                </li>

                                                <li>
                                                    <i className="icofont-check"></i>
                                                    {"Analyse des données et modélisation"}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about-us pt-100 rpt-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="section-title">
                            <h2>Qui sont nos Clients ?</h2>
                        </div>
                        <div className="col-12">
                            <p>Nos clients sont des chefs d’entreprises qui ont besoin de redresser une situation de
                                crise ou piloter un projet d’évolutions / de transformations. Souvent, la croissance de
                                leur entreprise est freinée, car ils ont des difficultés à s’entourer de personnes de
                                confiance à qui ils peuvent véritablement se déléguer, notamment pour mieux structurer
                                les fonctions clefs de l’entreprise ou faire monter leurs équipes en compétence.
                                L’accompagnement ponctuel d’experts opérationnels que nous proposons répond à ce besoin
                                d’une façon flexible.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about-us py-50">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-3 col-12">
                            <div className="project-info-text">
                                <h4>{"Secteurs d'activités"}</h4>
                            </div>
                        </div>
                        <div className="row col-lg-9 col-12">
                            <div className="col-lg-6">
                                <div className="blog-content-auto m-2">
                                    <b>{"Défense"}</b>
                                </div>
                                <div className="blog-content-auto m-2">
                                    <b>{"Automobile"}</b>
                                </div>
                                <div className="blog-content-auto m-2">
                                    <b>{"Plasturgie"}</b>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="blog-content-auto m-2">
                                    <b>{"Energie"}</b>
                                </div>
                                <div className="blog-content-auto m-2">
                                    <b>{"Ferroviaire"}</b>
                                </div>
                                <div className="blog-content-auto m-2">
                                    <b>{"Mécanique"}</b>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section className="about-us pb-50 rpt-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="section-title">
                            <h2>Nous rejoindre</h2>
                        </div>
                        <div className="col-12">
                            <b>
                                Vous êtes à la recherche d'une nouvelle opportunité et vous souhaitez intégrer notre
                                société, nous vous invitons à nous contacter <Link to={"/contact"}>ICI</Link>
                            </b>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CareerSection;