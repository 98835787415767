import detailsImg from '../../assets/images/service/energie.png';
const Energy = () => {

    return (
        <div className="col-lg-8">
            <div className="service-details-content">
                <div className="service-details-image">
                    <img src={detailsImg} alt="Service Details Image"/>
                </div>
                <h2>{"Energie"}</h2>
                <p>{"Protec Engineering intervient dans le domaine de l'énergie pour fournir des services de conseil et d'assistance technique aux entreprises impliquées dans la production, la distribution et la consommation d'énergie."}</p>
                <blockquote>{"Nos prestations en ingénierie énergétique couvrent différents domaines :"}</blockquote>

                <div className="col-lg-12">
                    <div className="blog-item-auto">
                        <div className="blog-content-auto">
                            <p>La planification et l'optimisation des infrastructures énergétiques : nous intervenons pour planifier et concevoir des infrastructures énergétiques telles que les centrales électriques, les réseaux de transport et de distribution, les installations de stockage, les stations de recharge pour véhicules électriques.</p>
                        </div>
                    </div>
                    <div className="blog-item-auto">
                        <div className="blog-content-auto">
                            <p>La conception et le développement de systèmes énergétiques.</p>
                        </div>
                    </div>
                    <div className="blog-item-auto">
                        <div className="blog-content-auto">
                            <p>La réglementation et les normes.</p>
                        </div>
                    </div>
                    <div className="blog-item-auto">
                        <div className="blog-content-auto">
                            <p>La gestion de projet : Gérer les projets énergétiques, en veillant à ce que les objectifs de qualité, de coûts et de délais soient atteints.</p>
                        </div>
                    </div>
                </div>
                <blockquote>{"Protec Engineering accompagne les entreprises pour relever les défis liés à la production et à la consommation d'énergie, en leurs fournissant des solutions technologiques innovantes, en améliorant leur efficacité énergétique et en les aidant à se conformer aux réglementations en vigueur."}</blockquote>
            </div>
        </div>
    );
}

export default Energy;