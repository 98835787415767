import detailsImg from '../../assets/images/service/aeronautique.jpg';
const Aeronautics = () => {

    return (
        <div className="col-lg-8">
            <div className="service-details-content">
                <div className="service-details-image">
                    <img src={detailsImg} alt="Service Details Image"/>
                </div>
                <h2>{"Aéronautique"}</h2>
                <p>{"Protec Engineering intervient dans le domaine aéronautique pour fournir des services de conseil et d'assistance technique aux entreprises impliquées dans la conception, la fabrication, l'assemblage et la maintenance de systèmes aéronautiques."}</p>
                <blockquote>{"Notre prestation couvre des différents domaines :\n"}</blockquote>
                <div className={"pb-25"}>
                    <div className="col-lg-12">
                        <div className="blog-item-auto">
                            <div className="blog-content-auto">
                                <p>La conception et le développement de systèmes aéronautiques.</p>
                            </div>
                        </div>
                        <div className="blog-item-auto">
                            <div className="blog-content-auto">
                                <p>L'optimisation des processus de production : nos experts interviennent pour améliorer l'efficacité opérationnelle en identifiant des axes d'optimisation des processus de production et en proposant des solutions pour les mettre en œuvre.</p>

                            </div>
                        </div>
                        <div className="blog-item-auto">
                            <div className="blog-content-auto">
                                <p>L'assurance qualité et la conformité réglementaire.</p>
                            </div>
                        </div>
                        <div className="blog-item-auto">
                            <div className="blog-content-auto">
                                <p>La gestion de projet : nos experts interviennent pour planifier et gérer les projets, en veillant à ce que les objectifs qualité, coûts et délais soient atteints.</p>

                            </div>
                        </div>
                        <div className="blog-item-auto">
                            <div className="blog-content-auto">
                                <p>L'analyse des données et la simulation.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <blockquote>{"Protec Engineering accompagne ses Clients à relever les défis technologiques et réglementaires du secteur aéronautique et à améliorer leur compétitivité sur le marché mondial."}</blockquote>
            </div>
        </div>
    );
}

export default Aeronautics;