import {Link} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import thaumb1 from '../../../assets/images/partners/renault-trucks.svg';
import thaumb2 from '../../../assets/images/partners/logo_ivecobus.png';
import thaumb3 from '../../../assets/images/partners/akkodis-logo-for-white-bg.png';
import thaumb4 from '../../../assets/images/partners/logo-ethicopex.png';
import thaumb5 from '../../../assets/images/partners/logo-PVL1.png';
import thaumb6 from '../../../assets/images/partners/SmallLogo-1.png';

const clients = [
    {
        thumb: thaumb1,
        title: 'Partner Logo'
    },
    {
        thumb: thaumb2,
        title: 'Partner Logo'
    },
    {
        thumb: thaumb3,
        title: 'Partner Logo'
    },
    {
        thumb: thaumb4,
        title: 'Partner Logo'
    },
    {
        thumb: thaumb5,
        title: 'Partner Logo'
    },
    {
        thumb: thaumb6,
        title: 'Partner Logo'
    },
]

const settings = {
    responsive: [
        {
            breakpoint : 425,
            settings : {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint : 800,
            settings : {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint : 1025,
            settings : {
                slidesToShow: 3,
                slidesToScroll: 1,
            }
        }
    ],
    dots: false,
    infinite: true,
    speed: 100,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autosize: true,
    arrows: false
};

const ClientsSection = () => {
    return (
        <section className="partner-section">
            <div className="container">
                <div className="partners-group pb-50">
                    <Slider {...settings}>
                        {
                            clients.map((data, index) => (
                                <div key={index}>
                                    <div className="partner-logo d-flex py-40 align-items-center justify-content-center"
                                         style={{height: "120px"}}>
                                        <Link to={"#"}>
                                            {(data.thumb === thaumb1)
                                                ? <img src={data.thumb} width={175} height={120} alt={data.title}/>
                                                : <img src={data.thumb} alt={data.title}
                                                       style={data.thumb === thaumb5 ? {width: "120px"} : { maxWidth: "175px"}}/>
                                            }

                                        </Link>
                                    </div>
                                </div>
                            ))
                        }
                    </Slider>
                </div>
            </div>
        </section>
    )
}

export default ClientsSection;
