import {Link} from "react-router-dom";
import ctaBG from '../../../assets/images/cta-bg.jpg'

const CallToAction = () => {
    return (

        <section className={`cta-section pb-120 rpb-100`}>
            <div className="container">
                <div className="contact-withus">
                    <div className="row text-justify">
                        <p>{"Nos services comprennent une large gamme d'activités telles que la conception et le développement de produits, la simulation numérique, la modélisation, la fabrication de prototypes, les tests et les validations. Nous sommes également spécialisés dans l'analyse et la résolution de problèmes techniques complexes, l'optimisation de processus industriels, ainsi que la mise en place de stratégies R&D. Nous sommes fiers de notre engagement envers la qualité, l'efficacité et la satisfaction de nos clients. Nous sommes convaincus que notre approche axée sur la prestation de service et notre expertise technique nous permettent de proposer des solutions innovantes et durables à nos clients dans les domaines industriels et R&D."}</p>
                    </div>
                </div>
            </div>
        </section>

    );
}

export default CallToAction;