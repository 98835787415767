import React, {useEffect} from "react";
import ContactSection from "../Utilities/ContactSection";
import PageBanner from "../Utilities/PageBanner";

const Contact = () => {
    return (
        <>
            <PageBanner pageTitle={'Contactez-nous'} currentPage={'Contact'}/>
            <ContactSection />
        </>
    );
}

export default Contact;