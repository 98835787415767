import SectionTitle from "../SectionTitle";

import thumb1 from '../../../assets/images/service/defense.png';
import thumb2 from '../../../assets/images/service/automobile.jpg';
import thumb3 from '../../../assets/images/service/aeronautique.jpg';
import thumb4 from '../../../assets/images/service/energie.png';

import { Link } from "react-router-dom";

const project = [
    {
        id : 'defense',
        thumb: thumb1,
        title: 'Défense'
    },
    {
        id : 'automobile',
        thumb: thumb2,
        title: 'Automobile'
    },
    {
        id : 'aeronautics',
        thumb: thumb3,
        title: 'Aéronautique'
    },
    {
        id : 'energy',
        thumb: thumb4,
        title: 'Energie'
    },
]

const goTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
}
const ProjectSection = () => {
    return (
        <section className="project-section pt-110 rpt-90">
            <div className="container">
                <div className="row">
                        <SectionTitle
                            title={'Domaines de compétences'}
                            extraClass={'text-center'}
                        />
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            {
                                project.map((data, index) => (
                                    <div className="col-md-6" key={index}>
                                        <div className="project-item p-20">
                                            <div className="project-image">
                                                <img src={data.thumb} alt={data.title} />
                                            </div>
                                            <div className="project-content" onClick={goTop}>
                                                <Link to={"/services?service=" + data.id} className="project-icon">
                                                    <i className="icofont-arrow-right"></i>
                                                </Link>
                                                <h5><Link to={"/services?service=" + data.id}>{data.title}</Link></h5>
                                            </div>
                                        </div>
                                    </div>

                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ProjectSection;