import detailsImg from '../../assets/images/service/automobile.jpg';

const Automobile = () => {

    const posts = [
        {
            title: 'Analyse des besoins du client et recommandations de solutions techniques.',
        },
        {
            title: 'Conception de pièces ou de systèmes pour l\'activité automobiles.',
        },
        {
            title: 'Simulation et test de performance.',
        },
        {
            title: 'Optimisation des processus de production.',
        },
        {
            title: 'Assistance technique lors de la mise en place de nouvelles installations de production.',
        },
        {
            title: ' Formation et coaching des équipes techniques.',
        },
    ]

    return (
        <div className="col-lg-8">
            <div className="service-details-content">
                <div className="service-details-image">
                    <img src={detailsImg} alt="Service Details Image"/>
                </div>
                <h2>{"Automobile"}</h2>
                <p>{"Protec Engineering intervient dans le secteur automobile pour aider à résoudre des problèmes techniques, à développer de nouveaux produits ou à améliorer des processus de production. \n" +
                    "Nous participons à toutes les étapes de vie du projet de la conception à la production en série."}</p>
                <blockquote>{"Notre prestation couvre des différents domaines : "}</blockquote>
                <div className="row">
                    {
                        posts.map((data, index) => (

                            <div className="col-lg-12" key={index}>
                                <div className="blog-item-auto">
                                    <div className="blog-content-auto">
                                        <p>{data.title}</p>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default Automobile;