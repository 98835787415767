import {useCallback, useState} from "react";

const ContactForm = () => {
    const [mailSend, setMailSend] = useState(false);
    const sendContact = useCallback((e) => {
        e.preventDefault()

        const form = e.currentTarget;

        try {
            fetch('https://dev.protec-engineering.fr/api/', {
                method: 'POST',
                body: JSON.stringify({
                    name: form.elements.name.value,
                    email: form.elements.email.value,
                    tel: form.elements.number.value,
                    message: form.elements.message.value,
                }),
                headers: {'Content-Type': 'application/json'}

            });
            setMailSend(true)
        } catch (e) {
            setMailSend(false)
            console.log(e.message)
        }

    })
    return (
        <div className="get-touch-right">
            <h3>{"Entrer en contact"}</h3>
            {
                mailSend && <div className={"alert alert-success"}>
                    <p className={"m-0"}>Votre message a bien été envoyé avec succés!</p>
                </div>
            }

            <div className="contact-form">
                <form onSubmit={sendContact}>
                    <div className="form-row">
                        <div className="col-12">
                            <input type="text" className="form-control custom-input" id="name" name="name"
                                   placeholder="Nom.." required/>
                        </div>
                        <div className="col-12">
                            <input type="email" className="form-control custom-input" id="email" name="email"
                                   placeholder="Email.." required/>
                        </div>
                        <div className="col-12">
                            <input type="text" className="form-control custom-input" id="number" name="number"
                                   placeholder="N° de téléphone.."/>
                        </div>
                        <div className="col-12">
                            <textarea className="form-control custom-textarea" id="message" name="message" rows="7"
                                      placeholder="Message.." required></textarea>
                        </div>
                    </div>
                    <div className="text-center col-12">
                        <button type="submit" className="btn submit">
                            {"Envoyer"}
                            <i className="icofont-arrow-right"></i>
                        </button>
                    </div>
                </form>
            </div>
        </div>

    );
}

export default ContactForm;