import {Link} from "react-router-dom";

import contactImg from '../../../assets/images/service/image005.png'

const serviceList = [
    {
        id: 'defense',
        service: 'Défense'
    },
    {
        id: 'automobile',
        service: 'Automobile'
    },
    {
        id: 'aeronautics',
        service: 'Aéronautique'
    },
    {
        id: 'energy',
        service: 'Energie'
    },
]
const Sidebar = (props) => {
    const {getService, selected} = props;
    return (
        <div className="service-sidebar">
            <div className="sidebar-widget service-widget">
                <h4>{"Tous les services"}</h4>
                <ul>
                    {
                        serviceList.map((data, index) => (
                            <li key={index} onClick={() => getService(data.id)}>
                                <Link className={selected === data.id ? 'active' : ''} to={"#"}>{data.service}</Link>
                            </li>
                        ))
                    }
                </ul>
            </div>
            <div className="sidebar-widget service-video-widget text-center"
                 style={{background: `url(${contactImg})`}}>
                <div className="project-text">
                    <h4>{"Besoin d'un expert"}</h4>
                    <Link to={"#"}>{"07 83 89 93 11"}</Link>
                </div>
            </div>
        </div>

    );
}

export default Sidebar;