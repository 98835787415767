import Sidebar from "./Sidebar";
import {useCallback, useState} from "react";
import Defense from "../../services/Defence";
import Aeronautics from "../../services/Aeronautics";
import Automobile from "../../services/Automobile";
import Energy from "../../services/Energy";

const ServiceDetailsSection = (service) => {

    const [selectedService, setSelectedService] = useState(service.service ?? 'defense')

    return (
        <section className="service-details pt-120 rpt-100 pb-115 rpb-95">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <Sidebar getService={setSelectedService} selected={selectedService}/>
                    </div>
                    {(() => {
                        switch (selectedService) {
                            case 'energy':
                                return <Energy/>;
                            case 'automobile':
                                return <Automobile/>;
                            case 'aeronautics':
                                return <Aeronautics/>;
                            case 'defense':
                            default:
                                return <Defense/>;
                        }
                    })()}
                </div>
            </div>
        </section>
    );
}

export default ServiceDetailsSection;