import React, {useEffect} from "react";
import PageBanner from "../Utilities/PageBanner";
import CareerSection from "../Utilities/AboutSection";

const Careers = () => {
    return (
        <>
            <PageBanner
                pageTitle={'Carrières'}
                currentPage={'Carrières'}
            />
            <CareerSection />
        </>
    );
}

export default Careers;