import {Link} from 'react-router-dom';
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import heroShape from '../../../assets/images/hero/hero-shape.png'
import heroBg from '../../../assets//images/hero/hero-bg.jpg'
import heroBg2 from '../../../assets//images/hero/hero-bg-3.png'

const HeroBanner = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        autoplaySpeed:4000,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autosize:true,
        arrows: false,
        pauseOnHover: false
    }
    return <Slider {...settings}>
        <div>
            <npm  className="hero-section" style={{background: `url(${heroBg})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center"}}>
                <div className="hero-shape">
                    <img src={heroShape} alt="notre expertises"/>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="hero-content">
                                <h1>{"Protec Engineering"}</h1>
                                <p>{"accompagne les entreprises dans la mise en œuvre de la stratégie, le conseil en organisation, l'amélioration continue de la performance et l'aide à la prise de décision"}</p>
                                <Link to={"/services"} className="btn theme-btn">
                                    {"Consulter nos services"}
                                    <i className="icofont-double-right"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </npm>
        </div>
        <div>
            <section className="hero-section" style={{background: `url(${heroBg2})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center"}}>
                <div className="hero-shape">
                    <img src={heroShape} alt="notre expertises"/>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="hero-content">
                                <h1>{"Expertise & Conseil"}</h1>
                                <p>{"Des solutions innovantes pour répondre à tous vos besoins"}</p>
                                <Link to={"/services"} className="btn theme-btn">
                                    {"Consulter nos services"}
                                    <i className="icofont-double-right"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </Slider>
}

export default HeroBanner;