import React, {useEffect} from "react";

import CallToAction from "../Utilities/CallToAction";
import HeroBanner from "../Utilities/HeroBnner";
import ProjectSection from "../Utilities/ProjectSection";
import AboutUsSection from "../Utilities/AboutUsSection";
import ClientsSection from "../Utilities/ClientsSection";

const Home = () => {
    return (
        <>
            <HeroBanner />
            <AboutUsSection />
            <ProjectSection />
            <CallToAction />
            <ClientsSection />
        </>
    );
}
export default Home;