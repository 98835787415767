import { Link } from "react-router-dom";

import logo from '../../../assets/images/logo.png';

const Logo = (goTop) => {
    return (
        <div className="logo-outer d-flex align-items-center">
            <div className="logo" onClick={goTop}>
                <Link to={"/"}>
                    <img width={230} src={logo} alt={'Envotek'} />
                </Link>
            </div>
        </div>
    );
}

export default Logo;